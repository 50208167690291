<template>
  <!--  <v-card-->
  <!--    class="mx-auto"-->
  <!--    width="374"-->
  <!--    elevation="0"-->
  <!--  >-->
  <!--    <v-form>-->
  <!--      <v-select-->
  <!--        :items="categories.map(c => c.title)"-->
  <!--        v-model="select"-->
  <!--        label="Выберите услугу"-->
  <!--        class="mx-4"-->
  <!--        color="teal"-->
  <!--      ></v-select>-->

  <!--      <v-text-field-->
  <!--        v-model="title"-->
  <!--        label="Наименование процедуры"-->
  <!--        prepend-icon="mdi-pencil"-->
  <!--        color="teal"-->
  <!--        class="mx-4"-->
  <!--      ></v-text-field>-->

  <!--      <v-text-field-->
  <!--        v-model="subtitle"-->
  <!--        label="Краткое описание"-->
  <!--        prepend-icon="mdi-information"-->
  <!--        color="teal"-->
  <!--        class="mx-4"-->
  <!--      ></v-text-field>-->

  <!--      <v-text-field-->
  <!--        v-model="price"-->
  <!--        label="Цена (руб)"-->
  <!--        prepend-icon="mdi-cash-100"-->
  <!--        color="teal"-->
  <!--        class="mx-4"-->
  <!--        type="number"-->
  <!--      ></v-text-field>-->

  <!--      <v-btn @click="createHandler"-->
  <!--             v-if="item === undefined" text color="teal">-->
  <!--        Создать-->
  <!--      </v-btn>-->

  <!--      <v-btn @click="changeHandler"-->
  <!--             v-else text color="teal">-->
  <!--        Изменить-->
  <!--      </v-btn>-->

  <!--      <v-btn @click.prevent.stop="eraseHandler"-->
  <!--             text color="teal">-->
  <!--        Очистить-->
  <!--      </v-btn>-->
  <!--    </v-form>-->

  <!--    <v-divider class="mt-4 mb-4"></v-divider>-->

  <!--    <v-container>-->
  <!--      <v-list>-->
  <!--        <v-list-item-group-->
  <!--          color="teal"-->
  <!--          v-model="item"-->
  <!--        >-->
  <!--          <v-card-text class="black&#45;&#45;text text-center">-->
  <!--            Для изменения существующей процедуры выберите запись и отредактируйте форму выше, для создания новой-->
  <!--            процедуры отмените выделение-->
  <!--          </v-card-text>-->

  <!--          <v-list-item-->
  <!--            v-for="service in getServicesOfCategory"-->
  <!--            :key="service._id"-->
  <!--            class="elevation-3 mb-5"-->
  <!--          >-->
  <!--            <v-list-item-content>-->
  <!--              <v-card rounded="xl">-->
  <!--                <v-img  src="/img/cardImage1.jpg"></v-img>-->
  <!--              </v-card>-->
  <!--              -->
  <!--            </v-list-item-content>-->
  <!--            <br>-->
  <!--            <v-list-item-action>-->
  <!--              <v-btn @click.prevent.stop="deleteHandler(service)"-->
  <!--                     text color="error">-->
  <!--                <v-icon>mdi-delete</v-icon>-->
  <!--              </v-btn>-->
  <!--            </v-list-item-action>-->
  <!--          </v-list-item>-->
  <!--        </v-list-item-group>-->
  <!--      </v-list>-->
  <!--    </v-container>-->

  <!--  </v-card>-->
  <div>
    <v-select
      :items="categories.map(c => c.title)"
      v-model="select"
      label="Выберите услугу"
      class="mx-4"
      color="teal"
    ></v-select>
    <div class="d-flex flex-wrap justify-space-evenly my-5">
      <v-card
        v-for="service in getServicesOfCategory"
        :key="service._id"
        class="mx-auto mb20 d-flex flex-column align-content-start"
        outlined
        elevation="3"
        max-width="344"
        rounded="xl"
      >
        <!--        <v-img :src="service.imageUrl ? service.imageUrl : service.image"-->
        <!--               max-height="400"-->
        <!--               class="align-end"-->
        <!--        >-->
        <!--          <v-card-title-->
        <!--            :class="service.image || service.imageUrl ? 'white&#45;&#45;text font-weight-bold' : 'teal&#45;&#45;text'"-->
        <!--            :style="service.imageUrl || service.image ? '-webkit-text-stroke: 1px black' : ''"-->
        <!--          >-->
        <!--            {{ service.title }}-->
        <!--          </v-card-title>-->
        <!--        </v-img>-->

        <ImageForCardWithTitle
          :image="service.image"
          :image-url="service.imageUrl"
          :title="service.title"
          main-theme-color="teal"
        />

        <v-card-actions v-if="service.touched" class="justify-space-between">
          <v-btn
            @click.prevent.stop="changeHandler(service)" text color="red">
            Сохранить (не сохранено)
          </v-btn>
        </v-card-actions>

        <v-card-subtitle class="flex-grow-1 teal--text text--lighten-1">
          {{ service.subtitle }}
        </v-card-subtitle>

        <v-card-subtitle class="teal--text text--lighten-1 font-weight-bold">
          Цена: {{ service.price }} руб
        </v-card-subtitle>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            icon
            color="teal"
            @click="showServiceForm(service)"
          >
            <v-icon>{{ service.show ? 'mdi-chevron-up' : 'mdi-lead-pencil' }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="service.show">
            <v-divider></v-divider>
            <v-form class="mx-5 my-5">
              <v-file-input
                v-model="service.imageFile"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Выбрать изображение"
                prepend-icon="mdi-file-image"
                label="Изображение для процедуры"
                color="teal"
                @change="setPreviewUrl(service); findChangesAndSetTouched()"
              ></v-file-input>

              <v-text-field
                v-model="service.title"
                label="Наименование процедуры"
                prepend-icon="mdi-pencil"
                color="teal"
                @keyup="findChangesAndSetTouched()"
              ></v-text-field>

              <v-text-field
                v-model="service.subtitle"
                label="Краткое описание"
                prepend-icon="mdi-information"
                color="teal"
                @keyup="findChangesAndSetTouched()"
              ></v-text-field>

              <v-text-field
                v-model="service.price"
                label="Стоимость процедуры (руб)"
                prepend-icon="mdi-cash-100"
                color="teal"
                type="number"
                @keyup="findChangesAndSetTouched()"
              ></v-text-field>

              <v-card-actions class="justify-space-between">
                <v-btn
                  @click.prevent.stop="changeHandler(service)" text :color="service.touched ? 'red' : 'teal'">
                  Сохранить
                </v-btn>

                <v-btn @click.prevent.stop="deleteHandler(service)"
                       text color="error">
                  Удалить
                </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </v-expand-transition>
      </v-card>
    </div>


    <v-divider class="my-5" v-if="categories"></v-divider>

    <div class="d-flex flex-wrap justify-space-evenly" v-if="select">
      <div class="align-self-start justify-center flex-grow-1 mx-4">
        <h3>Создание новой процедуры</h3>

        <v-form>
          <v-file-input
            v-model="imageFile"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Выбрать изображение"
            prepend-icon="mdi-file-image"
            label="Изображение для процедуры"
            color="teal"
            @change="setImageUrl"
          ></v-file-input>

          <v-text-field
            v-model="title"
            label="Наименование процедуры"
            prepend-icon="mdi-pencil"
            color="teal"
          ></v-text-field>

          <v-text-field
            v-model="subtitle"
            label="Краткое описание"
            prepend-icon="mdi-information"
            color="teal"
          ></v-text-field>

          <v-text-field
            v-model="price"
            label="Стоимость процедуры (руб)"
            prepend-icon="mdi-cash-100"
            color="teal"
            type="number"
          ></v-text-field>

          <v-btn @click.prevent.stop="createHandler"
                 text color="teal">
            Создать
          </v-btn>

          <v-btn @click.prevent.stop="eraseHandler"
                 text color="teal">
            Очистить
          </v-btn>
        </v-form>
      </div>

      <div v-if="title || subtitle || imageUrl" class="align-self-start mx-4">
        <div class="d-flex justify-center">
          <h3 class="mb-5 red--text">Предпросмотр</h3>
        </div>

        <div class="d-flex justify-center">
          <v-card class="my-4 elevation-5"
                  max-width="344"
                  rounded="lg">

            <ImageForCardWithTitle
              :image-url="imageUrl"
              :title="title"
              main-theme-color="teal"
            />

            <v-card-subtitle class="flex-grow-1 teal--text text--lighten-1">
              {{ subtitle }}
            </v-card-subtitle>

            <v-card-subtitle class="teal--text text--lighten-1 font-weight-bold">
              Цена: {{ price }} руб
            </v-card-subtitle>

            <v-card-actions>
              <v-btn text disabled>
                Предпросмотр
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageForCardWithTitle from "@/components/ImageForCardWithTitle";

export default {
  name: "Services",
  components: {ImageForCardWithTitle},
  data: () => ({
    categories: [],
    services: [],
    item: undefined,
    select: "",
    service: "",
    title: "",
    subtitle: "",
    price: "",
    imageFile: null,
    imageUrl: false,
    originalServices: ""
  }),

  computed: {
    category() {
      return this.categories.find(c => c.title === this.select)
    },

    getServicesOfCategory() {
      if (this.category) {
        return this.services.filter(s => s.category === this.category._id)
      }
      return []
    }
  },

  methods: {
    async loadServiceList() {
      const json = await this.authFetch('/service/load-service-list-for-user')
      if (json.status === 200) {
        this.originalServices = JSON.parse(JSON.stringify(json.services))
        this.services = json.services.map(service => {
          service.show = false
          service.imageFile = null
          service.touched = false
          return service
        })
      }
    },

    async loadCategoryList() {
      const json = await this.authFetch('/category/load-category-list-for-user')
      if (json.status === 200) this.categories = json.categories
    },

    async createHandler() {
      const formData = new FormData()
      formData.append('category', this.category._id)
      formData.append('title', this.title.trim())
      formData.append('subtitle', this.subtitle.trim())
      formData.append('price', this.price)
      formData.append('image', this.imageFile)

      let json = await this.authFetchFormData('/service/create-service', formData)
      if (json.status === 201)
        if (json.service) {
          const newService = json.service
          this.originalServices.push(JSON.parse(JSON.stringify(newService)))
          newService.show = false
          newService.imageFile = null
          newService.imageUrl = false
          newService.touched = false
          this.services.push(newService)
          this.title = ''
          this.subtitle = ''
          this.price = ''
          this.imageFile = null
          this.imageUrl = ''
          this.findChangesAndSetTouched()
        }
    },

    findChangesAndSetTouched() {
      for (let index in this.services) {
        this.services[index].touched = !!(this.services[index].imageFile ||
          this.services[index].imageUrl ||
          this.services[index].title !== this.originalServices[index].title ||
          this.services[index].subtitle !== this.originalServices[index].subtitle ||
          this.services[index].price !== this.originalServices[index].price)
      }
    },

    // async createHandler() {
    //   const title = this.title
    //   const subtitle = this.subtitle
    //   const price = this.price
    //   const category = this.category._id
    //   const json = await this.authFetch(
    //     '/service/create-service',
    //     {title, subtitle, price, category})
    //   if (json.status === 201) await this.loadServiceList()
    // },

    async changeHandler(service) {
      const formData = new FormData()
      formData.append('id', service._id)
      formData.append('title', service.title.trim())
      formData.append('subtitle', service.subtitle.trim())
      formData.append('price', service.price)
      formData.append('image', service.imageFile)

      const json = await this.authFetchFormData(
        '/service/change-service',
        formData
      )
      if (json.status === 200) await this.updateServiceList(json.service)
    },

    async updateServiceList(updatedService) {
      if (updatedService) {
        this.originalServices = this.originalServices.map(oldService => {
          if (updatedService._id === oldService._id) return JSON.parse(JSON.stringify(updatedService))
          else return oldService
        })

        this.services = this.services.map(oldService => {
          if (updatedService._id === oldService._id) {
            updatedService.show = false
            updatedService.imageFile = null
            updatedService.imageUrl = false
            updatedService.touched = false
            return updatedService
          } else return oldService
        })

        this.originalServices = JSON.parse(JSON.stringify(this.services))
        this.findChangesAndSetTouched()
      }
    },

    // async changeHandler() {
    //   const id = this.service._id
    //   const title = this.title
    //   const subtitle = this.subtitle
    //   const price = this.price
    //   const json = await this.authFetch(
    //     '/service/change-service',
    //     {id, title, subtitle, price})
    //   if (json.status === 200) await this.loadServiceList()
    // },

    async deleteHandler(service) {
      if (!window.confirm(
        `После удаления процедура продолжит отображаться в уже существующих записях клиентов, но возможность ее редактирования будет утрачена.\n \nВы уверены что хотите удалить процедуру "${service.title}"?`)
      ) return

      const json = await this.authFetch('/service/delete-service', {id: service._id})
      if (json.status === 200) await this.loadServiceList()
    },

    eraseHandler() {
      this.imageFile = null
      this.imageUrl = ""
      this.title = ""
      this.subtitle = ""
      this.price = ""
    },

    setImageUrl() {
      if (this.imageFile) this.imageUrl = URL.createObjectURL(this.imageFile)
      else this.imageUrl = ''
    },

    setPreviewUrl(service) {
      if (service.imageFile) service.imageUrl = URL.createObjectURL(service.imageFile)
      else service.imageUrl = ''
    },

    showServiceForm(service) {
      service.show = !service.show
    },
  },

  async mounted() {
    await this.loadServiceList()
    await this.loadCategoryList()
    if (this.$route.query.id) {
      this.select = this.categories.find(c => c._id === this.$route.query.id).title
    } else {
      this.select = null
    }
  }
}
</script>

<style scoped>

</style>
